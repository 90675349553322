import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Airtable from 'airtable'
import Text from '../../common/Text'
import Space from '../../common/Space'
import { persistData, getPersistedData } from '../../../utils/localStorageHelpers'

// Airtable columns
const labelColName = 'Label'
const threeMo2020ColName = '3 months 06/30 2020'
const threeMo2019ColName = '3 months ended 06/30 2019'
const sixMo2020ColName = '6 months ended 06/30 2020'
const sixMo2019ColName = '6 months ended 06/30 2019'
const detail2020ColName = '06/30 2020'
const detail2019ColName = '12/31 2019'

// configure Airtable
Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: process.env.GATSBY_AIRTABLE_API_KEY,
})
const AIRTABLE_BASE = Airtable.base('appv8vEYJ0MgFY9lP')

const StyledTable = styled.table`
  width: 100%;
  font-family: ${p => p.theme.fonts.family.body};
  margin: 0 auto;

  caption {
    font-weight: 700;
    margin-bottom: 1.5em;
  }
  .text-weight-book {
    font-weight: 400 !important;
  }
  td,
  th {
    text-align: left;
    border-bottom: solid 1px gray;
    padding: 0.3em;
  }
  td.w-60,
  th.w-60 {
    width: 60%;
  }
  td.text-align-right,
  th.text-align-right {
    text-align: right;
  }
  td.text-align-center {
    text-align: center;
  }
  .text-header {
    font-weight: 400;
  }
  .large-header {
    font-size: 1.2em;
  }
  .indent {
    padding-left: 1em;
  }
  .mr-2 {
    margin-right: 1em;
  }
  .mt-2 {
    margin-top: 1em;
  }
  .mt-4 {
    margin-top: 2em;
  }
  .border-top {
    border-top: solid 1px black;
  }
  .no-border {
    border: none;
  }
  .subtotal,
  .total,
  .total2 {
    position: relative;
  }
  .subtotal:before {
    content: '';
    background: black;
    position: absolute;
    top: -4px;
    right: 0;
    height: 1px;
    width: 75%;
  }
  .subtotal.full-width:before {
    width: 100%;
  }
  .subtotal.space-between {
    display: flex;

    div {
      width: 50%;
      text-align: right;
      border-bottom: 1px solid black;

      &:not(:first-of-type) {
        margin-left: 12px;
      }
    }
  }
  .total:after {
    content: '';
    background: black;
    position: absolute;
    bottom: -4px;
    right: 0;
    height: 1px;
    width: 75%;
  }
  .total2:after {
    content: '';
    background: black;
    position: absolute;
    bottom: -7px;
    right: 0;
    height: 1px;
    width: 75%;
  }
  .spacer {
    padding: 0.8em;
  }
  .spacer-row-subtotal {
    .subtotal {
      height: 19px;
    }
  }
  .no-wrap {
    white-space: nowrap;
  }
`

const formatCurrency = currency =>
  currency ? currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : <span>&mdash;</span>

const OverviewTable = data => {
  const overviewData = data.data

  return (
    <StyledTable
      summary="Column one has the revenue source, other columns show the amounts by date period"
      id="ae_revenue"
      className="financial-table"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <thead>
        <tr>
          <td className="no-border" />
          <th colSpan="2" id="threemonths" scope="col" className="text-align-right no-wrap no-border">
            Three months ended June 30,
          </th>
          <th colSpan="2" id="sixmonths" scope="col" className="text-align-right no-wrap no-border">
            Six months ended June 30,
          </th>
        </tr>
        <tr>
          <td className="no-border" />
          <th id="3m2020" scope="col" colSpan="2" className="no-border no-wrap">
            <div className="subtotal full-width space-between">
              <div>2020</div>
              <div>2019</div>
            </div>
          </th>
          <th id="6m2020" scope="col" colSpan="2" className="no-border no-wrap">
            <div className="subtotal full-width space-between">
              <div>2020</div>
              <div>2019</div>
            </div>
          </th>
        </tr>
        <tr>
          <td role="presentation" />
          <td colSpan="4" className="text-align-center">
            ( In thousands, except per share data )
          </td>
        </tr>
      </thead>
      <tbody>
        {overviewData.Revenue && (
          <tr>
            <th id="revenue" className="text-header">
              Revenue
            </th>
            <td headers="threemonths 3m2020 revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(overviewData.Revenue[threeMo2020ColName])}
            </td>
            <td headers="threemonths 3m2019 revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(overviewData.Revenue[threeMo2019ColName])}
            </td>
            <td headers="sixmonths 6m2020 revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(overviewData.Revenue[sixMo2020ColName])}
            </td>
            <td headers="sixmonths 6m2019 revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(overviewData.Revenue[sixMo2019ColName])}
            </td>
          </tr>
        )}

        {overviewData['Cost of Revenue'] && (
          <tr>
            <th id="costofrevenue" className="text-header">
              Cost of revenue
            </th>
            <td headers="threemonths 3m2020 costofrevenue" className="text-align-right no-border">
              {formatCurrency(overviewData['Cost of Revenue'][threeMo2020ColName])}
            </td>
            <td headers="threemonths 3m2019 costofrevenue" className="text-align-right no-border">
              {formatCurrency(overviewData['Cost of Revenue'][threeMo2019ColName])}
            </td>
            <td headers="sixmonths 6m2020 costofrevenue" className="text-align-right no-border">
              {formatCurrency(overviewData['Cost of Revenue'][sixMo2020ColName])}
            </td>
            <td headers="sixmonths 6m2019 costofrevenue" className="text-align-right no-border">
              {formatCurrency(overviewData['Cost of Revenue'][sixMo2019ColName])}
            </td>
          </tr>
        )}

        <tr className="spacer-row-subtotal">
          <td role="presentation" />
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
        </tr>

        {overviewData['Gross Profit'] && (
          <tr>
            <th id="grossprofit" className="text-header">
              Gross profit
            </th>
            <td headers="threemonths 3m2020 grossprofit" className="text-align-right">
              {formatCurrency(overviewData['Gross Profit'][threeMo2020ColName])}
            </td>
            <td headers="threemonths 3m2019 grossprofit" className="text-align-right">
              {formatCurrency(overviewData['Gross Profit'][threeMo2019ColName])}
            </td>
            <td headers="sixmonths 6m2020 grossprofit" className="text-align-right">
              {formatCurrency(overviewData['Gross Profit'][sixMo2020ColName])}
            </td>
            <td headers="sixmonths 6m2019 grossprofit" className="text-align-right">
              {formatCurrency(overviewData['Gross Profit'][sixMo2019ColName])}
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>
        <tr>
          <th id="oe" colSpan="5" scope="colgroup" className="text-header">
            Operating expenses:
          </th>
        </tr>

        {overviewData['Selling and Marketing'] && (
          <tr>
            <th id="sam" className="text-header">
              Selling and marketing
            </th>
            <td headers="threemonths 3m2020 oe sam" className="text-align-right">
              {formatCurrency(overviewData['Selling and Marketing'][threeMo2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe sam" className="text-align-right">
              {formatCurrency(overviewData['Selling and Marketing'][threeMo2019ColName])}
            </td>
            <td headers="sixmonths 6m2020 oe sam" className="text-align-right">
              {formatCurrency(overviewData['Selling and Marketing'][sixMo2020ColName])}
            </td>
            <td headers="sixmonths 6m2019 oe sam" className="text-align-right">
              {formatCurrency(overviewData['Selling and Marketing'][sixMo2019ColName])}
            </td>
          </tr>
        )}

        {overviewData['Research and Development'] && (
          <tr>
            <th id="rad" className="text-header">
              Research and development
            </th>
            <td headers="threemonths 3m2020 oe rad" className="text-align-right">
              {formatCurrency(overviewData['Research and Development'][threeMo2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe rad" className="text-align-right">
              {formatCurrency(overviewData['Research and Development'][threeMo2019ColName])}
            </td>
            <td headers="sixmonths 6m2020 oe rad" className="text-align-right">
              {formatCurrency(overviewData['Research and Development'][sixMo2020ColName])}
            </td>
            <td headers="sixmonths 6m2019 oe rad" className="text-align-right">
              {formatCurrency(overviewData['Research and Development'][sixMo2019ColName])}
            </td>
          </tr>
        )}

        {overviewData['General and Administrative'] && (
          <tr>
            <th id="ga" className="text-header">
              General and administrative
            </th>
            <td headers="threemonths 3m2020 oe ga" className="text-align-right no-border">
              {formatCurrency(overviewData['General and Administrative'][threeMo2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe ga" className="text-align-right no-border">
              {formatCurrency(overviewData['General and Administrative'][threeMo2019ColName])}
            </td>
            <td headers="sixmonths 6m2020 oe ga" className="text-align-right no-border">
              {formatCurrency(overviewData['General and Administrative'][sixMo2020ColName])}
            </td>
            <td headers="sixmonths 6m2019 oe ga" className="text-align-right no-border">
              {formatCurrency(overviewData['General and Administrative'][sixMo2019ColName])}
            </td>
          </tr>
        )}

        {overviewData['Total Operating Expenses'] && (
          <tr>
            <th id="toe" className="text-header">
              Total operating expenses
            </th>
            <td headers="threemonths 3m2020 oe toe" className="text-align-right">
              <div className="subtotal">
                {formatCurrency(overviewData['Total Operating Expenses'][threeMo2020ColName])}
              </div>
            </td>
            <td headers="threemonths 3m2019 oe toe" className="text-align-right">
              <div className="subtotal">
                {formatCurrency(overviewData['Total Operating Expenses'][threeMo2019ColName])}
              </div>
            </td>
            <td headers="sixmonths 6m2020 oe toe" className="text-align-right">
              <div className="subtotal">
                {formatCurrency(overviewData['Total Operating Expenses'][sixMo2020ColName])}
              </div>
            </td>
            <td headers="sixmonths 6m2019 oe toe" className="text-align-right">
              <div className="subtotal">
                {formatCurrency(overviewData['Total Operating Expenses'][sixMo2019ColName])}
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>

        {overviewData['Operating Loss'] && (
          <tr>
            <th id="ol" className="text-header">
              Operating loss
            </th>
            <td headers="threemonths 3m2020 oe ol" className="text-align-right">
              ({formatCurrency(overviewData['Operating Loss'][threeMo2020ColName])})
            </td>
            <td headers="threemonths 3m2019 oe ol" className="text-align-right">
              ({formatCurrency(overviewData['Operating Loss'][threeMo2019ColName])})
            </td>
            <td headers="sixmonths 6m2020 oe ol" className="text-align-right">
              ({formatCurrency(overviewData['Operating Loss'][sixMo2020ColName])})
            </td>
            <td headers="sixmonths 6m2019 oe ol" className="text-align-right">
              ({formatCurrency(overviewData['Operating Loss'][sixMo2019ColName])})
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="oie" colSpan="5" scope="colgroup" className="text-header">
            Other expenses:
          </th>
        </tr>

        {overviewData['Change in fair value of warrant liability'] && (
          <tr>
            <th id="ol" className="text-header">
              Change in fair value of warrant liability
            </th>
            <td headers="threemonths 3m2020 oe oie" className="text-align-right">
              ({formatCurrency(overviewData['Change in fair value of warrant liability'][threeMo2020ColName])})
            </td>
            <td headers="threemonths 3m2019 oe oie" className="text-align-right">
              {formatCurrency(overviewData['Change in fair value of warrant liability'][threeMo2019ColName])}
            </td>
            <td headers="sixmonths 6m2020 oe oie" className="text-align-right">
              ({formatCurrency(overviewData['Change in fair value of warrant liability'][sixMo2020ColName])})
            </td>
            <td headers="sixmonths 6m2019 oe oie" className="text-align-right">
              {formatCurrency(overviewData['Change in fair value of warrant liability'][sixMo2019ColName])}
            </td>
          </tr>
        )}

        {overviewData['Interest expense'] && (
          <tr>
            <th id="iein" className="text-header">
              Interest expense
            </th>
            <td headers="threemonths 3m2020 oe iein" className="text-align-right no-border">
              ({formatCurrency(overviewData['Interest expense'][threeMo2020ColName])})
            </td>
            <td headers="threemonths 3m2019 oe iein" className="text-align-right no-border">
              ({formatCurrency(overviewData['Interest expense'][threeMo2019ColName])})
            </td>
            <td headers="sixmonths 6m2020 oe iein" className="text-align-right no-border">
              ({formatCurrency(overviewData['Interest expense'][sixMo2020ColName])})
            </td>
            <td headers="sixmonths 6m2019 oe iein" className="text-align-right no-border">
              ({formatCurrency(overviewData['Interest expense'][sixMo2019ColName])})
            </td>
          </tr>
        )}

        {overviewData['Total other expenses'] && (
          <tr>
            <th id="toe" className="text-header">
              Total other expenses
            </th>
            <td headers="threemonths 3m2020 oe toe" className="text-align-right">
              <div className="subtotal">
                ({formatCurrency(overviewData['Total other expenses'][threeMo2020ColName])})
              </div>
            </td>
            <td headers="threemonths 3m2019 oe toe" className="text-align-right">
              <div className="subtotal">
                ({formatCurrency(overviewData['Total other expenses'][threeMo2019ColName])})
              </div>
            </td>
            <td headers="sixmonths 6m2020 oe toe" className="text-align-right">
              <div className="subtotal">({formatCurrency(overviewData['Total other expenses'][sixMo2020ColName])})</div>
            </td>
            <td headers="sixmonths 6m2019 oe toe" className="text-align-right">
              <div className="subtotal">({formatCurrency(overviewData['Total other expenses'][sixMo2019ColName])})</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>

        {overviewData['Net loss'] && (
          <tr>
            <th id="nl" className="text-header">
              Net loss
            </th>
            <td headers="threemonths 3m2020 oe nl" className="text-align-right">
              ({formatCurrency(overviewData['Net loss'][threeMo2020ColName])})
            </td>
            <td headers="threemonths 3m2019 oe nl" className="text-align-right">
              ({formatCurrency(overviewData['Net loss'][threeMo2019ColName])})
            </td>
            <td headers="sixmonths 6m2020 oe nl" className="text-align-right">
              ({formatCurrency(overviewData['Net loss'][sixMo2020ColName])})
            </td>
            <td headers="sixmonths 6m2019 oe nl" className="text-align-right">
              ({formatCurrency(overviewData['Net loss'][sixMo2019ColName])})
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {overviewData['Dividends on Series A Convertible preferred stock'] && (
          <tr>
            <th id="dosacps" className="text-header no-border">
              Dividends on Series A Convertible preferred stock
            </th>
            <td headers="threemonths 3m2020 oe dosacps" className="text-align-right no-border">
              <div className="total">
                ({formatCurrency(overviewData['Dividends on Series A Convertible preferred stock'][threeMo2020ColName])}
                )
              </div>
            </td>
            <td headers="threemonths 3m2019 oe dosacps" className="text-align-right no-border">
              <div className="total">
                ({formatCurrency(overviewData['Dividends on Series A Convertible preferred stock'][threeMo2019ColName])}
                )
              </div>
            </td>
            <td headers="sixmonths 6m2020 oe dosacps" className="text-align-right no-border">
              <div className="total">
                ({formatCurrency(overviewData['Dividends on Series A Convertible preferred stock'][sixMo2020ColName])})
              </div>
            </td>
            <td headers="sixmonths 6m2019 oe dosacps" className="text-align-right no-border">
              <div className="total">
                ({formatCurrency(overviewData['Dividends on Series A Convertible preferred stock'][sixMo2019ColName])})
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {overviewData['Net loss available to common stockholders'] && (
          <tr>
            <th id="nlatcs" className="text-header no-border">
              Net loss available to common stockholders
            </th>
            <td headers="threemonths 3m2020 oe nlatcs" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(overviewData['Net loss available to common stockholders'][threeMo2020ColName])})
                </div>
              </div>
            </td>
            <td headers="threemonths 3m2019 oe nlatcs" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(overviewData['Net loss available to common stockholders'][threeMo2019ColName])})
                </div>
              </div>
            </td>
            <td headers="sixmonths 6m2020 oe nlatcs" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(overviewData['Net loss available to common stockholders'][sixMo2020ColName])})
                </div>
              </div>
            </td>
            <td headers="sixmonths 6m2019 oe nlatcs" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(overviewData['Net loss available to common stockholders'][sixMo2019ColName])})
                </div>
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {overviewData['Net loss per common share-basic and diluted'] && (
          <tr>
            <th id="nlpcsad" className="text-header no-border">
              Net loss per common share-basic and diluted
            </th>
            <td headers="threemonths 3m2020 oe nlpcsad" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(overviewData['Net loss per common share-basic and diluted'][threeMo2020ColName])})
                </div>
              </div>
            </td>
            <td headers="threemonths 3m2019 oe nlpcsad" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(overviewData['Net loss per common share-basic and diluted'][threeMo2019ColName])})
                </div>
              </div>
            </td>
            <td headers="sixmonths 6m2020 oe nlpcsad" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(overviewData['Net loss per common share-basic and diluted'][sixMo2020ColName])})
                </div>
              </div>
            </td>
            <td headers="sixmonths 6m2019 oe nlpcsad" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(overviewData['Net loss per common share-basic and diluted'][sixMo2019ColName])})
                </div>
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {overviewData['Weighted average common shares outstanding-basic and diluted'] && (
          <tr>
            <th id="wacsoad" className="text-header no-border">
              Weighted average common shares outstanding-basic and diluted
            </th>
            <td headers="threemonths 3m2020 oe wacsoad" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(
                    overviewData['Weighted average common shares outstanding-basic and diluted'][threeMo2020ColName]
                  )}
                </div>
              </div>
            </td>
            <td headers="threemonths 3m2019 oe wacsoad" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(
                    overviewData['Weighted average common shares outstanding-basic and diluted'][threeMo2019ColName]
                  )}
                </div>
              </div>
            </td>
            <td headers="sixmonths 6m2020 oe wacsoad" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(
                    overviewData['Weighted average common shares outstanding-basic and diluted'][sixMo2020ColName]
                  )}
                </div>
              </div>
            </td>
            <td headers="sixmonths 6m2019 oe wacsoad" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(
                    overviewData['Weighted average common shares outstanding-basic and diluted'][sixMo2019ColName]
                  )}
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  )
}

const DetailTable = data => {
  const detailData = data.data

  return (
    <StyledTable
      summary="Column one has the asset or liability, other columns show the amounts by date period"
      id="assets_liabilities"
      className="financial-table mt-4"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <thead>
        <tr>
          <td className="no-border" />
          <th id="6302020" scope="col" className="text-align-right no-border no-wrap">
            June 30, 2020
          </th>
          <th id="12312019" scope="col" className="text-align-right no-border no-wrap">
            December 31, 2019
          </th>
        </tr>

        <tr>
          <td role="presentation" className="no-border" />
          <td role="presentation" className="no-border">
            <div className="subtotal full-width" />
          </td>
          <td role="presentation" className="no-border">
            <div className="subtotal full-width" />
          </td>
        </tr>

        <tr>
          <td />
          <td colSpan="2" className="text-align-right no-wrap">
            ( In thousands, except per share data )
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th id="assets" colSpan="3" scope="colgroup" className="large-header">
            ASSETS
          </th>
        </tr>
        <tr>
          <th id="current_assets" colSpan="3" scope="colgroup" headers="assets" className="indent">
            Current assets:
          </th>
        </tr>

        {detailData.Cash && (
          <tr>
            <th headers="assets current_assets" id="cash" className="text-header indent">
              Cash
            </th>
            <td headers="assets current_assets peaoca 6302020" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(detailData.Cash[detail2020ColName])}
            </td>
            <td headers="assets current_assets peaoca 12312019" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(detailData.Cash[detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Accounts receivable, net of allowance for doubtful accounts of $0 and $63, respectively'] && (
          <tr>
            <th headers="assets current_assets" id="arn" className="text-header indent">
              Accounts receivable, net of allowance for doubtful accounts of $0 and $63, respectively
            </th>
            <td headers="assets current_assets arn 6302020" className="text-align-right">
              {formatCurrency(
                detailData['Accounts receivable, net of allowance for doubtful accounts of $0 and $63, respectively'][
                  detail2020ColName
                ]
              )}
            </td>
            <td headers="assets current_assets arn 12312019" className="text-align-right">
              {formatCurrency(
                detailData['Accounts receivable, net of allowance for doubtful accounts of $0 and $63, respectively'][
                  detail2019ColName
                ]
              )}
            </td>
          </tr>
        )}

        {detailData['Unbilled receivables'] && (
          <tr>
            <th headers="assets current_assets" id="ur" className="text-header indent">
              Unbilled receivables
            </th>
            <td headers="assets current_assets ur 6302020" className="text-align-right">
              {formatCurrency(detailData['Unbilled receivables'][detail2020ColName])}
            </td>
            <td headers="assets current_assets ur 12312019" className="text-align-right">
              {formatCurrency(detailData['Unbilled receivables'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Deferred costs, short term'] && (
          <tr>
            <th headers="assets current_assets" id="dcst" className="text-header indent">
              Deferred costs, short term
            </th>
            <td headers="assets current_assets dcst 6302020" className="text-align-right">
              {formatCurrency(detailData['Deferred costs, short term'][detail2020ColName])}
            </td>
            <td headers="assets current_assets dcst 12312019" className="text-align-right">
              {formatCurrency(detailData['Deferred costs, short term'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Debt issuance costs, net'] && (
          <tr>
            <th headers="assets current_assets" id="dicn" className="text-header indent">
              Debt issuance costs, net
            </th>
            <td headers="assets current_assets dicn 6302020" className="text-align-right">
              {formatCurrency(detailData['Debt issuance costs, net'][detail2020ColName])}
            </td>
            <td headers="assets current_assets dicn 12312019" className="text-align-right">
              {formatCurrency(detailData['Debt issuance costs, net'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Prepaid expenses and other current assets'] && (
          <tr>
            <th headers="assets current_assets" id="peaoca" className="text-header indent">
              Prepaid expenses and other current assets
            </th>
            <td headers="assets current_assets peaoca 6302020" className="text-align-right no-border">
              {formatCurrency(detailData['Prepaid expenses and other current assets'][detail2020ColName])}
            </td>
            <td headers="assets current_assets peaoca 12312019" className="text-align-right no-border">
              {formatCurrency(detailData['Prepaid expenses and other current assets'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Total current assets'] && (
          <tr>
            <th headers="assets current_assets" id="tca" className="text-header indent">
              Total current assets
            </th>
            <td headers="assets current_assets tca 6302020" className="text-align-right">
              <div className="subtotal">{formatCurrency(detailData['Total current assets'][detail2020ColName])}</div>
            </td>
            <td headers="assets current_assets tca 12312019" className="text-align-right">
              <div className="subtotal">{formatCurrency(detailData['Total current assets'][detail2019ColName])}</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        {detailData['Property and equipment, net of accumulated depreciation of $159 and $124, respectively'] && (
          <tr>
            <th headers="assets current_assets" id="paen" className="text-header indent">
              Property and equipment, net of accumulated depreciation of $159 and $124, respectively
            </th>
            <td headers="assets current_assets paen 6302020" className="text-align-right">
              {formatCurrency(
                detailData['Property and equipment, net of accumulated depreciation of $159 and $124, respectively'][
                  detail2020ColName
                ]
              )}
            </td>
            <td headers="assets current_assets paen 12312019" className="text-align-right">
              {formatCurrency(
                detailData['Property and equipment, net of accumulated depreciation of $159 and $124, respectively'][
                  detail2019ColName
                ]
              )}
            </td>
          </tr>
        )}

        {detailData['Right of use assets'] && (
          <tr>
            <th headers="assets current_assets" id="roua" className="text-header indent">
              Right of use assets
            </th>
            <td headers="assets current_assets roua 6302020" className="text-align-right">
              {formatCurrency(detailData['Right of use assets'][detail2020ColName])}
            </td>
            <td headers="assets current_assets roua 12312019" className="text-align-right">
              {formatCurrency(detailData['Right of use assets'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Deferred costs, long term'] && (
          <tr>
            <th headers="assets current_assets" id="dclt" className="text-header indent">
              Deferred costs, long term
            </th>
            <td headers="assets current_assets dclt 6302020" className="text-align-right">
              {formatCurrency(detailData['Deferred costs, long term'][detail2020ColName])}
            </td>
            <td headers="assets current_assets dclt 12312019" className="text-align-right">
              {formatCurrency(detailData['Deferred costs, long term'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Intangible assets, net of accumulated amortization of $4,086 and $3,710, respectively'] && (
          <tr>
            <th headers="assets current_assets" id="ian" className="text-header indent">
              Intangible assets, net of accumulated amortization of $4,086 and $3,710, respectively
            </th>
            <td headers="assets current_assets ian 6302020" className="text-align-right">
              {formatCurrency(
                detailData['Intangible assets, net of accumulated amortization of $4,086 and $3,710, respectively'][
                  detail2020ColName
                ]
              )}
            </td>
            <td headers="assets current_assets ian 12312019" className="text-align-right">
              {formatCurrency(
                detailData['Intangible assets, net of accumulated amortization of $4,086 and $3,710, respectively'][
                  detail2019ColName
                ]
              )}
            </td>
          </tr>
        )}

        {detailData.Goodwill && (
          <tr>
            <th headers="assets current_assets" id="gw" className="text-header indent">
              Goodwill
            </th>
            <td headers="assets current_assets gw 6302020" className="text-align-right no-border">
              {formatCurrency(detailData.Goodwill[detail2020ColName])}
            </td>
            <td headers="assets current_assets gw 12312019" className="text-align-right no-border">
              {formatCurrency(detailData.Goodwill[detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Total Assets'] && (
          <tr>
            <th headers="assets current_assets" id="ta" className="text-header indent">
              Total assets
            </th>
            <td headers="assets current_assets ta 6302020" className="text-align-right no-border">
              <div className="subtotal">
                <div className="total">
                  <div className="total2">
                    <span className="mr-2">$</span>
                    {formatCurrency(detailData['Total Assets'][detail2020ColName])}
                  </div>
                </div>
              </div>
            </td>
            <td headers="assets current_assets ta 12312019" className="text-align-right no-border">
              <div className="subtotal">
                <div className="total">
                  <div className="total2">
                    <span className="mr-2">$</span>
                    {formatCurrency(detailData['Total Assets'][detail2019ColName])}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="liabilities" colSpan="3" scope="colgroup" className="large-header">
            LIABILITIES AND STOCKHOLDERS' EQUITY
          </th>
        </tr>
        <tr>
          <th id="current_liabilities" colSpan="3" scope="colgroup" headers="liabilities" className="indent">
            Current liabilities:
          </th>
        </tr>

        {detailData['Accounts payable and accrued expenses'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="apaae" className="text-header indent">
              Accounts payable and accrued expenses
            </th>
            <td headers="liabilities current_liabilities apaae 6302020" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(detailData['Accounts payable and accrued expenses'][detail2020ColName])}
            </td>
            <td headers="liabilities current_liabilities apaae 12312019" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(detailData['Accounts payable and accrued expenses'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Finance lease liabilities'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="fll" className="text-header indent">
              Finance lease liabilities
            </th>
            <td headers="liabilities current_liabilities fll 6302020" className="text-align-right">
              {formatCurrency(detailData['Finance lease liabilities'][detail2020ColName])}
            </td>
            <td headers="liabilities current_liabilities fll 12312019" className="text-align-right">
              {formatCurrency(detailData['Finance lease liabilities'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Operating lease liabilities'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="oll" className="text-header indent">
              Operating lease liabilities
            </th>
            <td headers="liabilities current_liabilities oll 6302020" className="text-align-right">
              {formatCurrency(detailData['Operating lease liabilities'][detail2020ColName])}
            </td>
            <td headers="liabilities current_liabilities oll 12312019" className="text-align-right">
              {formatCurrency(detailData['Operating lease liabilities'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Warrant liability'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="wl" className="text-header indent">
              Warrant liability
            </th>
            <td headers="liabilities current_liabilities wl 6302020" className="text-align-right">
              {formatCurrency(detailData['Warrant liability'][detail2020ColName])}
            </td>
            <td headers="liabilities current_liabilities wl 12312019" className="text-align-right">
              {formatCurrency(detailData['Warrant liability'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Deferred revenue'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="dr" className="text-header indent">
              Deferred revenue
            </th>
            <td headers="liabilities current_liabilities dr 6302020" className="text-align-right no-border">
              {formatCurrency(detailData['Deferred revenue'][detail2020ColName])}
            </td>
            <td headers="liabilities current_liabilities dr 12312019" className="text-align-right no-border">
              {formatCurrency(detailData['Deferred revenue'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Total current liabilities'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="tcl" className="text-header indent">
              Total current liabilities
            </th>
            <td headers="liabilities current_liabilities tcl 6302020" className="text-align-right">
              <div className="subtotal" />
              {formatCurrency(detailData['Total current liabilities'][detail2020ColName])}
            </td>
            <td headers="liabilities current_liabilities tcl 12312019" className="text-align-right">
              <div className="subtotal" />
              {formatCurrency(detailData['Total current liabilities'][detail2019ColName])}
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="longterm_liabilities" colSpan="3" scope="colgroup" headers="liabilities" className="indent">
            Long term liabilities:
          </th>
        </tr>

        {detailData['Finance lease liabilities_2'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" id="fllll" className="text-header indent">
              Finance lease liabilities
            </th>
            <td headers="liabilities longterm_liabilities fllll 6302020" className="text-align-right">
              {formatCurrency(detailData['Finance lease liabilities_2'][detail2020ColName])}
            </td>
            <td headers="liabilities longterm_liabilities fllll 12312019" className="text-align-right">
              {formatCurrency(detailData['Finance lease liabilities_2'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Operating lease liabilities_2'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" id="ollll" className="text-header indent">
              Operating lease liabilities
            </th>
            <td headers="liabilities longterm_liabilities ollll 6302020" className="text-align-right">
              {formatCurrency(detailData['Operating lease liabilities_2'][detail2020ColName])}
            </td>
            <td headers="liabilities longterm_liabilities ollll 12312019" className="text-align-right">
              {formatCurrency(detailData['Operating lease liabilities_2'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Deferred revenue_2'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" id="drll" className="text-header indent">
              Deferred revenue
            </th>
            <td headers="liabilities longterm_liabilities drll 6302020" className="text-align-right">
              {formatCurrency(detailData['Deferred revenue_2'][detail2020ColName])}
            </td>
            <td headers="liabilities longterm_liabilities drll 12312019" className="text-align-right">
              {formatCurrency(detailData['Deferred revenue_2'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Term loan'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" id="lpll" className="text-header indent">
              Term loan
            </th>
            <td headers="liabilities longterm_liabilities lpll 6302020" className="text-align-right no-border">
              {formatCurrency(detailData['Term loan'][detail2020ColName])}
            </td>
            <td headers="liabilities longterm_liabilities lpll 12312019" className="text-align-right no-border">
              {formatCurrency(detailData['Term loan'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Total liabilities'] && (
          <tr>
            <th headers="liabilities" id="tl" className="text-header indent">
              Total liabilities
            </th>
            <td headers="liabilities tl 6302020" className="text-align-right">
              <div className="subtotal">{formatCurrency(detailData['Total liabilities'][detail2020ColName])}</div>
            </td>
            <td headers="liabilities tl 12312019" className="text-align-right">
              <div className="subtotal">{formatCurrency(detailData['Total liabilities'][detail2019ColName])}</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="stockholders_equity" scope="colgroup" headers="liabilities" className="indent">
            <div>Stockholders' equity:</div>
            <div className="text-weight-book">Preferred stock, $0.00001 par value, 10,000 shares authorized</div>
          </th>
        </tr>

        {detailData[
          'Series A Convertible Preferred Stock, $0.00001 par value, 200 shares designated, 100 and 105 shares issued and outstanding as of June 30, 2020 and December 31, 2019, respectively'
        ] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="seriesa" className="text-header indent w-60">
              <div>Series A Convertible Preferred Stock</div>
              <div className="text-weight-book">
                $0.00001 par value, 200 shares designated, 100 and 105 shares issued and outstanding as of June 30, 2020
                and December 31, 2019, respectively
              </div>
            </th>
            <td headers="liabilities stockholders_equity seriesa 6302020" className="text-align-right">
              {formatCurrency(
                detailData[
                  'Series A Convertible Preferred Stock, $0.00001 par value, 200 shares designated, 100 and 105 shares issued and outstanding as of June 30, 2020 and December 31, 2019, respectively'
                ][detail2020ColName]
              )}
            </td>
            <td headers="liabilities stockholders_equity seriesa 12312019" className="text-align-right">
              {formatCurrency(
                detailData[
                  'Series A Convertible Preferred Stock, $0.00001 par value, 200 shares designated, 100 and 105 shares issued and outstanding as of June 30, 2020 and December 31, 2019, respectively'
                ][detail2019ColName]
              )}
            </td>
          </tr>
        )}

        {detailData[
          'Common stock, $0.00001 par value, 50,000 shares authorized, 9,113 and 8,877 shares issued and outstanding as of June 30, 2020 and December 31, 2019, respectively'
        ] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="common" className="text-header indent w-60">
              <div>Common stock</div>
              <div className="text-weight-book">
                $0.00001 par value, 50,000 shares authorized, 9,113 and 8,877 shares issued and outstanding as of June
                30, 2020 and December 31, 2019, respectively
              </div>
            </th>
            <td headers="liabilities stockholders_equity common 6302020" className="text-align-right">
              {formatCurrency(
                detailData[
                  'Common stock, $0.00001 par value, 50,000 shares authorized, 9,113 and 8,877 shares issued and outstanding as of June 30, 2020 and December 31, 2019, respectively'
                ][detail2020ColName]
              )}
            </td>
            <td headers="liabilities stockholders_equity common 12312019" className="text-align-right">
              {formatCurrency(
                detailData[
                  'Common stock, $0.00001 par value, 50,000 shares authorized, 9,113 and 8,877 shares issued and outstanding as of June 30, 2020 and December 31, 2019, respectively'
                ][detail2019ColName]
              )}
            </td>
          </tr>
        )}

        {detailData['Additional paid-in capital'] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="apc" className="text-header indent">
              Additional paid-in capital
            </th>
            <td headers="liabilities stockholders_equity apc 6302020" className="text-align-right">
              {formatCurrency(detailData['Additional paid-in capital'][detail2020ColName])}
            </td>
            <td headers="liabilities stockholders_equity apc 12312019" className="text-align-right">
              {formatCurrency(detailData['Additional paid-in capital'][detail2019ColName])}
            </td>
          </tr>
        )}

        {detailData['Accumulated deficit'] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="ad" className="text-header indent">
              Accumulated deficit
            </th>
            <td headers="liabilities stockholders_equity ad 6302020" className="text-align-right no-border">
              ({formatCurrency(detailData['Accumulated deficit'][detail2020ColName])})
            </td>
            <td headers="liabilities stockholders_equity ad 12312019" className="text-align-right no-border">
              ({formatCurrency(detailData['Accumulated deficit'][detail2019ColName])})
            </td>
          </tr>
        )}

        {detailData["Total stockholder's (deficit) equity"] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="tse" className="text-header indent">
              Total stockholders' (deficit) equity
            </th>
            <td headers="liabilities stockholders_equity tse 6302020" className="text-align-right no-border">
              <div className="subtotal">
                ({formatCurrency(detailData["Total stockholder's (deficit) equity"][detail2020ColName])})
              </div>
            </td>
            <td headers="liabilities stockholders_equity tse 12312019" className="text-align-right no-border">
              <div className="subtotal">
                {formatCurrency(detailData["Total stockholder's (deficit) equity"][detail2019ColName])}
              </div>
            </td>
          </tr>
        )}

        <tr className="spacer-row-subtotal">
          <td role="presentation" />
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
        </tr>

        {detailData["Total liabilities and stockholder's (deficit) equity"] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="tlase" className="text-header indent">
              Total liabilities and stockholders' (deficit) equity
            </th>
            <td headers="liabilities stockholders_equity tlase 6302020" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>
                  {formatCurrency(
                    detailData["Total liabilities and stockholder's (deficit) equity"][detail2020ColName]
                  )}
                </div>
              </div>
            </td>
            <td headers="liabilities stockholders_equity tlase 12312019" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>
                  {formatCurrency(
                    detailData["Total liabilities and stockholder's (deficit) equity"][detail2019ColName]
                  )}
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  )
}

const StyledEarningsReport = styled.div`
  margin: 100px 0 0;
`

const checkExpiredData = timestamp => {
  const dayMs = 1000 * 60 * 60 * 24
  const dayAgo = Date.now() - dayMs
  return timestamp > dayAgo
}

const EarningsReport = () => {
  const [overviewData, setOverviewData] = useState({})
  const [detailData, setDetailData] = useState({})

  const fetchTableData = tableName =>
    new Promise(resolve => {
      const data = {}

      // if no / expired data exists then query AirTable
      AIRTABLE_BASE(tableName)
        .select({
          maxRecords: 1000,
          pageSize: 100,
          view: 'Grid view',
        })
        .eachPage(
          (records, fetchNextPage) => {
            // This function (`page`) will get called for each page of records.
            records.forEach(record => {
              let label = record.fields[labelColName]
              if (data[label]) {
                console.warn('Multiple earnings results with same name: ', label)
                label += '_2'
              }

              if (tableName === 'Overview') {
                data[label] = {
                  [threeMo2020ColName]: record.fields[threeMo2020ColName],
                  [threeMo2019ColName]: record.fields[threeMo2019ColName],
                  [sixMo2020ColName]: record.fields[sixMo2020ColName],
                  [sixMo2019ColName]: record.fields[sixMo2019ColName],
                }
              } else {
                data[label] = {
                  [detail2020ColName]: record.fields[detail2020ColName],
                  [detail2019ColName]: record.fields[detail2019ColName],
                }
              }
            })

            // To fetch the next page of records, call `fetchNextPage`.
            // If there are more records, `page` will get called again.
            // If there are no more records, `done` will get called.
            fetchNextPage()
          },
          err => {
            // on finish
            if (err) {
              console.error(err)
              resolve({})
            } else {
              resolve(data)
            }
          }
        )
    })

  useEffect(async () => {
    // check local storage and timestamp for existing data
    const ls = getPersistedData()
    const lsEarningsData = ls['earnings-data-q2-2020']

    if (lsEarningsData && checkExpiredData(lsEarningsData.timestamp)) {
      // exists and is not expired
      setOverviewData(lsEarningsData.overviewData)
      setDetailData(lsEarningsData.detailData)
    } else {
      // Request AirTable base data
      const overviewRes = await fetchTableData('Overview')
      const detailRes = await fetchTableData('Detail')
      // set data timestamp
      persistData(
        Object.assign(ls, {
          'earnings-data-q2-2020': {
            timestamp: Date.now(),
            overviewData: overviewRes,
            detailData: detailRes,
          },
        })
      )
      setOverviewData(overviewRes)
      setDetailData(detailRes)
    }
  }, [])

  return (
    <StyledEarningsReport>
      <Text center heading3 aria-level="2" role="heading">
        AUDIOEYE, INC. CONSOLIDATED STATEMENTS OF OPERATIONS <br />
        (unaudited)
      </Text>
      <Space height={60} />
      <OverviewTable data={overviewData} />
      <Space height={100} />
      <Text center heading3 aria-level="2" role="heading">
        AUDIOEYE, INC. CONSOLIDATED BALANCE SHEETS <br />
        (unaudited)
      </Text>
      <Space height={60} />
      <DetailTable data={detailData} />
    </StyledEarningsReport>
  )
}

export default EarningsReport
